$lato-font-path: '/assets/font' !default;
$druk-font-path: '/assets/font';
$fraktionmono-font-path: '/assets/font';
$tradegothicltstd-font-path: '/assets/font';

// ---

@import './fonts/lato-regular';
@import './fonts/lato-black';
@import './fonts/lato-blackitalic';
@import './fonts/lato-bold';
@import './fonts/lato-bolditalic';
@import './fonts/druk-wide-medium';
@import './fonts/fraktionmono-black';
@import './fonts/fraktionmono-medium';
@import './fonts/fraktionmono-regular';
@import './fonts/tradegothicltstd';

// ---

$primary: #005582;
$grey-1: #0086ad;
$grey-2: #323232;
$grey-3: #404040;
$grey-4: #555555;
$grey-5: #aaaaaa;
$grey-6: #dedede;
$grey-7: #eaeaea;
$grey-8: #2d6da3;
$regular: 400;
$bold: 700;
$black: 900;

// ---

@for $index from 1 through 6 {
  h#{$index} {
    color: $grey-1 !important;
    font-weight: $black !important;
  }
}

// ---

body,
input {
  // background-color: white;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  -webkit-text-stroke: 0.25px rgba(0, 0, 0, 0.1);
}

a:hover,
a {
  cursor: pointer !important;
  text-decoration: unset;
}

body,
p,
input,
a,
.dropdown-menu > li > a {
  font-family: 'Lato';
  font-weight: $regular;
  line-height: 2rem;
  color: $grey-2;
  letter-spacing: 0.01em;
}

// ---

button.btn {
  border-radius: 0;
  padding: 0 30px 0 30px;
  line-height: 1;
  height: 50px;
  min-width: 50px;
  text-transform: uppercase;
  font-size: 1.25rem;
}

button.btn-primary {
  color: white !important;
  border-radius: 0 !important;
  border: 1px solid $primary !important;
  background-color: $primary !important;
  font-weight: $black !important;
  font-style: normal !important;
}
button.btn-primary:hover,
button.btn-primary:focus,
button.btn-primary:active {
  border-radius: 0 !important;
  border: 1px solid $grey-4 !important;
  color: white !important;
  background-color: $grey-4 !important;
}

button.btn-info {
  border-radius: 0 !important;
  color: white !important;
  // border: 1px solid $grey-2 !important;
  // background-color: $grey-2 !important;
  font-weight: $bold !important;
  font-style: normal !important;
}
button.btn-info:hover,
button.btn-info:focus,
button.btn-info:active {
  border-radius: 0 !important;
  color: white !important;
  border-color: $grey-8 !important;
  // border: 1px solid $grey-2 !important;
  // background-color: white !important;
}
.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
  background-color: $grey-8 !important;
}

button.btn-secondary {
  border-radius: 0 !important;
  color: white !important;
  border: 1px solid $grey-4 !important;
  background-color: $grey-4 !important;
  font-weight: $bold !important;
  font-style: normal !important;
}
button.btn-secondary:hover,
button.btn-secondary:focus,
button.btn-secondary:active {
  border-radius: 0 !important;
  border: 1px solid $grey-6 !important;
  color: $grey-4 !important;
  background-color: $grey-6 !important;
}

.btn-link {
  border-radius: 0 !important;
  color: $grey-1 !important;
  font-weight: $black !important;
  font-size: 12px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-radius: 0 !important;
  color: $primary !important;
}

// ---

/* .sidebar .nav {
  background-color: white;
}
.sidebar .nav-dropdown,
.sidebar .nav-link {
  background-color: unset !important;
}
.sidebar-compact .sidebar .nav-link,
.sidebar .nav-dropdown.open .nav-link,
.sidebar .nav-link {
  text-align: left;
  color: $grey-1;
}
.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  color: $primary !important;
}
.sidebar .nav .nav-item {
  font-weight: $black;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
}
.navbar-default {
  background-color: $grey-1;
  border: unset;
  border-radius: 0;
  border-bottom: 0.2rem solid $primary;
  -webkit-box-shadow: 0rem 0.05rem 1rem 0rem $grey-3;
  -moz-box-shadow: 0rem 0.05rem 1rem 0rem $grey-3;
  box-shadow: 0rem 0.05rem 1rem 0rem $grey-3;
  min-height: 6rem;
  margin-bottom: 2.75rem;
}
.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-text {
  color: $grey-7;
  font-size: 2rem;
  font-weight: $black;
  line-height: 2.87rem;
  margin-left: 0;
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: $primary;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: $primary;
  background-color: unset;
}
.navbar-brand > img {
  width: 3.5rem;
  height: auto;
}
.navbar-nav > li > .dropdown-menu {
  margin-top: -0.01rem;
  background-color: $grey-1;
  border: 0.25rem solid $primary;
  border-top: unset;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset;
} */

// ---

.dropdown-menu .divider {
  background-color: $grey-4;
}
.dropdown-menu > li > a {
  color: $grey-7;
  text-transform: uppercase;
  font-weight: $bold;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:active {
  color: $primary;
  background-color: unset;
}
