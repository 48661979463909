@font-face {
  font-family: "Lato";
  src: url("#{$lato-font-path}/lato-bold/lato-bold.eot");
  src: local("Lato Bold"), local("Lato-Bold"),
    url("#{$lato-font-path}/lato-bold/lato-bold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$lato-font-path}/lato-bold/lato-bold.woff2") format("woff2"),
    url("#{$lato-font-path}/lato-bold/lato-bold.woff") format("woff"),
    url("#{$lato-font-path}/lato-bold/lato-bold.ttf") format("truetype"),
    url("#{$lato-font-path}/lato-bold/lato-bold.svg#lato") format("svg");
  font-weight: 700;
  font-style: normal;
}
