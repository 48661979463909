/* General */
body {
  min-width: 320px;
}

/* Validation Borders and Text */
.red-borders {
  border: 1px solid #a94442 !important;
}

.red-text {
  color: #a94442;
}

.well {
  border: none;
  background-color: transparent;
  border-radius: 0 !important;
}
.row {
  .btn.btn-primary.json-editor-btn-collapse.json-editor-btntype-toggle {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    margin: 0 !important;
    outline: none !important;
    min-width: fit-content !important;
    margin-right: 8px !important;
    &:hover {
      opacity: 0.7;
    }
    i {
      color: #0086ad !important;
      font-size: 2.2rem;
    }
  }
}

div[data-schemapath="root.header"],
div[data-schemapath="root.footer"] {
  padding-top: 8px !important;
  & > h3 {
    & > span {
      display: initial !important;
    }
  }
}

.row > div[data-schemapath='root.header'],
.row > div[data-schemapath='root.header_template'],
div[data-schemapath='root.primes'],
div[data-schemapath='root.whiteSections'],
div[data-schemapath='root.quiFincantieriSection'],
div[data-schemapath='root.body'],
div[data-schemapath='root.calendar'],
div[data-schemapath='root.image_with_link'],
div[data-schemapath='root.footer'],
div[data-schemapath='root.footer_template'],
div[data-schemapath='root.article_image'],
div[data-schemapath='root.article_text'],
div[data-schemapath='root.article_url'],
div[data-schemapath='root.article_url_link'],
div[data-schemapath='root.article_pdf'],
div[data-schemapath='root.article_pdf_en'],
div[data-schemapath='root.array_area_add'],
div[data-schemapath='root.optionalSection'],
div[data-schemapath='root.images'],
div[data-schemapath='root.videos'],
div[data-schemapath='root.dynamic_date'],
div[data-schemapath='root.section_separator'],
div[data-schemapath='root.sections'],
div[data-schemapath='root.primes'],
div[data-schemapath='root.colors'],
div[data-schemapath='root.links'],
div[data-schemapath='root.fonts'],
div[data-schemapath='root.general_settings'],
div[data-schemapath='root.social_section'],
div[data-schemapath='root.header_template'],
div[data-schemapath='root.footer_template'],
div[data-schemapath='root.image_with_link'],
div[data-schemapath='root.link_press_complete'],
div[data-schemapath='root.documents'],
div[data-schemapath='root.sent_time'],
div[data-schemapath='root.sections_global_title'] {
  border: 1px solid #eee !important;
  border-radius: 5px;
  background-color: #fafafa !important;
  margin-bottom: 12px;
  padding: 0 8px;

  h3 {
    width: 100%;
    margin: 6px 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    div {
      margin-left: 0 !important;
    }
    span {
      white-space: nowrap;
    }
  }

  div.well {
    margin-bottom: 8px;
  }
  .well {
    // border: 1px solid #eee !important;
    background-color: #fcfcfc !important;
  }
}
div[data-schemapath="root.social_section.visible"],
div[data-schemapath="root.header_template.visible"],
div[data-schemapath="root.footer.visible"],
div[data-schemapath="root.header.visible"],
div[data-schemapath="root.footer_template.visible"] {
  border-bottom: 1px solid rgba($color: #ccc, $alpha: 0.2) !important;
  margin-bottom: 25px;
}
.row > div[data-schemapath="root.body"] {
  .well {
    border: none !important;
    padding: 10px 0;
  }
}

.row > div[data-schemapath="root.header.headerDefault.header"],
.row > div[data-schemapath="root.footer.footerDefault.footer"] {
  .form-group {
    display: none;
  }
}

.row {
  h3 {
    font-size: 20px;
  }
  .row {
    h3 {
      font-size: 16px;
    }
  }
}
.ui-dialog {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

div[data-schemapath="root.colors"],
div[data-schemapath="root.fonts"],
div[data-schemapath="root.social_section"] {
  .well.well-sm {
    padding: 25px 0 !important;
  }
}

div[data-schemapath="root.general_settings.colors"],
div[data-schemapath="root.general_settings.fonts"] {
  padding: 10px !important;
  .well.well-sm {
    padding: 25px 0 !important;
  }
}

div[data-schemapath="root.social_section.social"] {
  h3 {
    font-size: 20px !important;
  }
  .well.well-sm {
    .row {
      h3 {
        font-size: 16px !important;
      }
    }
  }
}
div[data-schemapath="root.sent_time"] {
  border: none !important;
  padding: 0 15px;
  background: #ffffff !important;
}

.color-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-left: 20px;
  border: 2px solid #ccc;
}
.color-pick-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

input.form-control.flatpickr-input {
  background-color: #fff !important;
}

.sortable-index {
  width: 100%;
  text-align: end;
  padding-left: 20px;
}
