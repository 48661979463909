@font-face {
  font-family: "Lato";
  src: url("#{$lato-font-path}/lato-regular/lato-regular.eot");
  src: local("Lato Regular"), local("Lato-Regular"),
    url("#{$lato-font-path}/lato-regular/lato-regular.eot?#iefix")
      format("embedded-opentype"),
    url("#{$lato-font-path}/lato-regular/lato-regular.woff2") format("woff2"),
    url("#{$lato-font-path}/lato-regular/lato-regular.woff") format("woff"),
    url("#{$lato-font-path}/lato-regular/lato-regular.ttf") format("truetype"),
    url("#{$lato-font-path}/lato-regular/lato-regular.svg#lato") format("svg");
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
}
