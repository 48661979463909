@font-face {
  font-family: "Lato";
  src: url("#{$lato-font-path}/lato-black/lato-black.eot");
  src: local("Lato Black"), local("Lato-Black"),
    url("#{$lato-font-path}/lato-black/lato-black.eot?#iefix")
      format("embedded-opentype"),
    url("#{$lato-font-path}/lato-black/lato-black.woff2") format("woff2"),
    url("#{$lato-font-path}/lato-black/lato-black.woff") format("woff"),
    url("#{$lato-font-path}/lato-black/lato-black.ttf") format("truetype"),
    url("#{$lato-font-path}/lato-black/lato-black.svg#lato") format("svg");
  font-weight: 900;
  font-style: normal;
}
