/* JsonEditor Controls */
label.control-label {
  width: 20%;
}

input.form-control {
  display: inline-block;
  width: 75%;
}

textarea.form-control {
  display: inline-block;
  width: 75%;
}

select.form-control {
  display: inline-block;
  width: auto;
}

.not-active a {
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
}

.btn-secondary {
  color: white;
  background-color: #808080 !important;

  &:hover {
    color: white;
    background-color: #606060 !important;
  }
}

/* WYSIWYG Summernote */
.note-toolbar {
  z-index: auto;
}

.dropdown-menu {
  z-index: 1;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:visited,
.nav-pills > li.active > a:active,
.nav-pills > li.active > a:focus {
  background-color: #323232;
}

.note-btn {
  min-width: 34px !important;
  height: 30px !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
}
