/* Common */
/* Headers */
/* Contents */
/* Forms */
/* List Items */
/* Messages */
/* Horizontal Menu */
/* Misc */
/* Predefined Colors */
/* Add your variable customizations of layout here */
/* open-sans-300 - latin */
/* @font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v13-latin-300.eot');
  /* IE9 Compat Modes */
/* src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/open-sans-v13-latin-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/open-sans-v13-latin-300.woff2') format('woff2'),
    url('../fonts/open-sans-v13-latin-300.woff') format('woff'),
    url('../fonts/open-sans-v13-latin-300.ttf') format('truetype'),
    url('../fonts/open-sans-v13-latin-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
/* }
/* open-sans-regular - latin */
/* @font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v13-latin-regular.eot');
  /* IE9 Compat Modes */
/* src: local('Open Sans'), local('OpenSans'),
    url('../fonts/open-sans-v13-latin-regular.eot#iefix') format('embedded-opentype'),
    url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'),
    url('../fonts/open-sans-v13-latin-regular.woff') format('woff'),
    url('../fonts/open-sans-v13-latin-regular.ttf') format('truetype'),
    url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
/* }
/* open-sans-700 - latin */
/* @font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v13-latin-700.eot');
  /* IE9 Compat Modes */
/* src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/open-sans-v13-latin-700.eot#iefix') format('embedded-opentype'),
    url('../fonts/open-sans-v13-latin-700.woff2') format('woff2'),
    url('../fonts/open-sans-v13-latin-700.woff') format('woff'),
    url('../fonts/open-sans-v13-latin-700.ttf') format('truetype'),
    url('../fonts/open-sans-v13-latin-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
/* }
/* Utils */
.clearfix:after {
  content: ' ';
  display: block;
  clear: both;
}

.card {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #ffffff;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.card.card-w-title {
  padding-bottom: 32px;
}
.card h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 24px 0;
}
.card h1:first-child {
  margin-top: 16px;
}
.card h2 {
  font-size: 22px;
  font-weight: 400;
}
.card h3 {
  font-size: 20px;
  font-weight: 400;
}
.card h4 {
  font-size: 18px;
  font-weight: 400;
}

.nopad {
  padding: 0;
}
.nopad .ui-panel-content {
  padding: 0;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.ui-shadow-1 {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ui-shadow-2 {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ui-shadow-3 {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ui-shadow-4 {
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ui-shadow-5 {
  -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.ui-g {
  -ms-flex-wrap: wrap;
}
.ui-g.form-group > div {
  padding: 8px;
}

.ui-panelgrid.form-group .ui-panelgrid-cell {
  padding: 8px;
}
.ui-panelgrid.ui-panelgrid-blank .ui-grid-responsive .ui-grid-row {
  border: 0 none;
}

.ui-selectoneradio.form-group .ui-grid-row > div,
.ui-selectmanycheckbox.form-group .ui-grid-row > div {
  padding: 8px;
}

.dashboard .card {
  height: 100%;
}
.dashboard .overview-box {
  padding: 0 !important;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0px !important;
}
.dashboard .overview-box i {
  font-size: 48px;
  margin-top: 20px;
  color: #ffffff;
}
.dashboard .overview-box .overview-box-name {
  font-size: 20px;
  display: inline-block;
  width: 100%;
  margin: 4px 0 10px 0;
  color: #ffffff;
}
.dashboard .overview-box .overview-box-count {
  color: #ffffff;
  font-size: 36px;
}
.dashboard .overview-box > div:last-child {
  text-align: left;
}
.dashboard .overview-box.overview-box-1 {
  background-color: #00acac;
}
.dashboard .overview-box.overview-box-2 {
  background-color: #2f8ee5;
}
.dashboard .overview-box.overview-box-3 {
  background-color: #6c76af;
}
.dashboard .overview-box.overview-box-4 {
  background-color: #efa64c;
}
.dashboard .task-list {
  overflow: hidden;
}
.dashboard .task-list > .ui-panel {
  min-height: 340px;
}
.dashboard .task-list .ui-panel-content {
  padding: 10px 0 !important;
}
.dashboard .task-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dashboard .task-list ul li {
  padding: 8px 16px;
  border-bottom: 1px solid #c9cdd2;
}
.dashboard .task-list ul li:first-child {
  margin-top: 10px;
}
.dashboard .task-list ul .ui-chkbox {
  vertical-align: middle;
  margin-right: 5px;
}
.dashboard .task-list ul .task-name {
  vertical-align: middle;
}
.dashboard .task-list ul i {
  color: #778087;
  float: right;
  font-size: 16px;
  margin-top: 5px;
}
.dashboard .contact-form {
  overflow: hidden;
}
.dashboard .contact-form .ui-panel {
  min-height: 340px;
}
.dashboard .contact-form .ui-g-12 {
  padding: 16px 10px;
}
.dashboard .contact-form .ui-button {
  margin-top: 20px;
}
.dashboard .contacts {
  overflow: hidden;
}
.dashboard .contacts > .ui-panel {
  min-height: 340px;
}
.dashboard .contacts .ui-panel-content {
  padding: 15px 0 10px 0 !important;
}
.dashboard .contacts ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dashboard .contacts ul li {
  border-bottom: 1px solid #c9cdd2;
}
.dashboard .contacts ul li a {
  padding: 9px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  display: block;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.dashboard .contacts ul li a .name {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
  color: #5f666c;
}
.dashboard .contacts ul li a .email {
  position: absolute;
  right: 10px;
  top: 30px;
  font-size: 14px;
  color: #778087;
}
.dashboard .contacts ul li a:hover {
  cursor: pointer;
  background-color: #f0f3f5;
}
.dashboard .contacts ul li:last-child {
  border: 0;
}
.dashboard .activity-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dashboard .activity-list li {
  border-bottom: 1px solid #c9cdd2;
  padding: 15px 0 9px 9px;
}
.dashboard .activity-list li .count {
  font-size: 24px;
  color: #ffffff;
  background-color: #03a9f4;
  font-weight: bold;
  display: inline-block;
  padding: 5px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.dashboard .activity-list li:first-child {
  border-top: 1px solid #c9cdd2;
}
.dashboard .activity-list li:last-child {
  border: 0;
}
.dashboard .activity-list li .ui-g-6:first-child {
  font-size: 18px;
  padding-left: 0;
}
.dashboard .activity-list li .ui-g-6:last-child {
  text-align: right;
  color: #778087;
}
.dashboard .timeline {
  height: 100%;
  box-sizing: border-box;
}
.dashboard .timeline > .ui-g .ui-g-3 {
  font-size: 14px;
  position: relative;
  border-right: 1px solid #bdbdbd;
}
.dashboard .timeline > .ui-g .ui-g-3 i {
  background-color: #ffffff;
  font-size: 24px;
  position: absolute;
  top: 6px;
  right: -12px;
}
.dashboard .timeline > .ui-g .ui-g-9 {
  padding-left: 1.5em;
}
.dashboard .timeline > .ui-g .ui-g-9 .event-text {
  color: #778087;
  font-size: 14px;
  display: block;
  padding-bottom: 20px;
}
.dashboard .timeline > .ui-g .ui-g-9 .event-content img {
  width: 100%;
}

.login-body .login-panel {
  height: 100%;
  width: 350px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 100px 50px;
  -webkit-box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: auto;
}
.login-body .login-panel .ui-g-12 {
  padding: 16px 0;
  text-align: center;
}
.login-body .login-panel .ui-g-12.logo-container img {
  margin-bottom: 20px;
}
.login-body .login-panel .ui-g-12.chkbox-container {
  text-align: left;
}
.login-body .login-panel .ui-g-12.chkbox-container .ui-outputlabel {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}
.login-body .login-panel .ui-g-12.button-container button {
  margin-bottom: 20px;
}

@media (min-width: 641px) {
  .login-body {
    background: url('../images/login/login-background.png') top left no-repeat;
    background-size: cover;
  }
}
@media (max-width: 640px) {
  .login-body {
    background: none;
  }
  .login-body .login-panel {
    width: 100%;
  }
}
body.exception-body .exception-top {
  height: 30%;
  position: relative;
}
body.exception-body .exception-top img {
  width: 100px;
  display: block;
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -50px;
}
body.exception-body .exception-bottom {
  height: 70%;
}
body.exception-body .exception-bottom .exception-wrapper {
  margin: 0 auto;
  width: 500px;
  padding: 50px;
  text-align: center;
  box-sizing: border-box;
}
body.exception-body .exception-bottom .exception-wrapper .exception-summary {
  display: block;
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}
body.exception-body .exception-bottom .exception-wrapper .exception-detail {
  display: block;
  color: #ffffff;
  margin-bottom: 50px;
}
body.exception-body .exception-bottom .exception-wrapper button {
  width: 75%;
  display: block;
  margin: 0 auto 100px auto;
}
body.exception-body .exception-bottom .exception-wrapper .logo-icon {
  width: 56px;
  display: block;
  margin: 0 auto 20px auto;
}
body.exception-body .exception-bottom .exception-wrapper .exception-footer {
  color: #ffffff;
}
body.exception-body.error-page {
  background-color: #ce5051;
}
body.exception-body.error-page .exception-top {
  background-color: #f0f3f5;
}
body.exception-body.access-page {
  background-color: #ce5051;
}
body.exception-body.access-page .exception-top {
  background-color: #f0f3f5;
}
body.exception-body.notfound-page {
  background-color: #2d353c;
}
body.exception-body.notfound-page .exception-top {
  background-color: #f0f3f5;
}

@media (max-width: 640px) {
  body.exception-body .exception-bottom .exception-wrapper {
    width: 100%;
  }
}
.landing-wrapper #header {
  width: 100%;
  min-height: 500px;
  background: url('../images/landing/landing-bg.png') top left no-repeat #f7f7f7;
  background-size: cover;
}
.landing-wrapper #header .header-top {
  width: 960px;
  margin: 0 auto;
  padding: 30px 0px;
}
.landing-wrapper #header .header-top .logo {
  display: inline-block;
  vertical-align: middle;
  width: 175px;
  height: 49px;
  background: url('../images/logo-white.png') top left no-repeat;
  background-size: 175px 49px;
}
.landing-wrapper #header .header-top #menu {
  float: right;
  list-style: none;
  margin: 20px 20px 0 0;
  padding: 0;
}
.landing-wrapper #header .header-top #menu li {
  float: left;
  display: block;
  margin-left: 30px;
}
.landing-wrapper #header .header-top #menu li a {
  padding: 3px 7px;
  color: #ffffff;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.landing-wrapper #header .header-top #menu li a:hover {
  color: #00acac;
  background-color: #f0f3f5;
}
.landing-wrapper #header .header-top #menu li i {
  display: none;
}
.landing-wrapper #header .header-top #menu.lmenu-active {
  display: block;
}
.landing-wrapper #header .header-top #menu-button {
  float: right;
  font-size: 36px;
  color: #ffffff;
  display: none;
}
.landing-wrapper #header .header-content {
  width: 960px;
  margin: 0 auto;
}
.landing-wrapper #header .header-content h1 {
  margin: 80px 0 0 0;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
}
.landing-wrapper #header .header-content h2 {
  margin: 10px 0 40px 0;
  font-weight: 300;
  font-size: 20px;
  color: #ffffff;
}
.landing-wrapper #header .header-content button .ui-button-text {
  padding: 10px 20px;
}
.landing-wrapper #features {
  width: 960px;
  margin: 0 auto;
  padding: 100px 0px;
  text-align: center;
}
.landing-wrapper #features p {
  color: #778087;
}
.landing-wrapper #features .ui-g-12 {
  padding: 2em 0.5em;
}
.landing-wrapper #features .feature-icon {
  text-align: center;
  box-sizing: border-box;
  height: 100px;
}
.landing-wrapper #features .feature-icon img {
  text-align: center;
  width: 100px;
}
.landing-wrapper #promotion {
  width: 100%;
  background: url('../images/landing/landing-overlay-1.png') 775px 370px no-repeat #2d353c;
  background-size: 400px auto;
  min-height: 500px;
}
.landing-wrapper #promotion .promotion-content {
  width: 960px;
  margin: 0 auto;
  padding: 50px 0 0 0;
  text-align: center;
  overflow: hidden;
}
.landing-wrapper #promotion .promotion-content h2 {
  font-size: 24px;
  color: #ffffff;
}
.landing-wrapper #promotion .promotion-content p {
  color: #ffffff;
}
.landing-wrapper #promotion .promotion-content img {
  margin-top: 50px;
  display: inline-block;
  height: 350px;
  position: relative;
  top: 25px;
}
.landing-wrapper #pricing {
  width: 100%;
  background: url('../images/landing/landing-overlay-2.png') 0px 300px no-repeat #ffffff;
  background-size: 600px auto;
  min-height: 400px;
}
.landing-wrapper #pricing .pricing-content {
  width: 960px;
  margin: 0 auto;
  padding: 75px 0;
  text-align: center;
  overflow: hidden;
}
.landing-wrapper #pricing .pricing-content div.ui-g-12 {
  padding: 1em;
}
.landing-wrapper #pricing .pricing-content .pricing-box {
  color: #ffffff;
}
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-header {
  margin: 0 auto;
  max-width: 200px;
  font-size: 20px;
  padding: 75px 0 75px 0px;
  box-sizing: border-box;
  margin-bottom: -100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-header .pricing-name,
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-header .price {
  display: inline-block;
  width: 100%;
  position: relative;
  top: -40px;
}
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-header .price {
  font-weight: 300;
}
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-features {
  text-align: left;
  padding: 30px 20px;
  min-height: 305px;
  box-sizing: border-box;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-features ul li {
  padding: 8px 14px;
}
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-features ul li i {
  margin-right: 8px;
  font-size: 18px;
}
.landing-wrapper #pricing .pricing-content .pricing-box .pricing-buy {
  padding: 20px 0;
}
.landing-wrapper #pricing .pricing-content .pricing-box.pricing-basic > div {
  background-color: #2dcece;
}
.landing-wrapper #pricing .pricing-content .pricing-box.pricing-standard > div {
  background-color: #2f8ee5;
}
.landing-wrapper #pricing .pricing-content .pricing-box.pricing-pro > div {
  background-color: #6c76af;
}
.landing-wrapper #video {
  background-color: #f7f7f7;
  min-width: 400px;
}
.landing-wrapper #video .video-content {
  width: 960px;
  margin: 0 auto;
  padding: 50px 0px 75px 0px;
  text-align: center;
}
.landing-wrapper #video .video-content h2 {
  font-weight: 400;
}
.landing-wrapper #video .video-content p {
  color: #778087;
}
.landing-wrapper #footer {
  width: 100%;
  background-color: #00acac;
  color: #ffffff;
  padding: 50px 0;
}
.landing-wrapper #footer .footer-content {
  width: 960px;
  margin: 0 auto;
}
.landing-wrapper #footer .footer-content .footer-left img {
  float: left;
  margin-right: 10px;
}
.landing-wrapper #footer .footer-content .footer-left span {
  margin-top: 4px;
  display: block;
  font-weight: 400;
}
.landing-wrapper #footer .footer-content .footer-right {
  text-align: right;
  font-size: 16px;
  padding-top: 24px;
}
.landing-wrapper #footer .footer-content .footer-right i {
  margin-right: 14px;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.landing-wrapper #footer .footer-content .footer-right i:hover {
  color: #000000;
}

@media (max-width: 1024px) {
  .landing-wrapper #header .header-top {
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .landing-wrapper #header .header-top #menu-button {
    display: inline-block;
  }
  .landing-wrapper #header .header-top #menu {
    z-index: 100;
    position: absolute;
    top: 86px;
    right: 30px;
    float: none;
    display: none;
    margin: 0;
    padding: 0;
    width: 225px;
    list-style: none;
    background-color: #ffffff;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .landing-wrapper #header .header-top #menu li {
    float: none;
    margin-left: 0;
  }
  .landing-wrapper #header .header-top #menu li a {
    font-size: 16px;
    display: block;
    padding: 0.5em 1em;
    color: #5f666c;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .landing-wrapper #header .header-top #menu li a:hover {
    background-color: #f0f3f5;
  }
  .landing-wrapper #header .header-content {
    width: 100%;
    padding: 0px 30px 60px 30px;
    box-sizing: border-box;
  }
  .landing-wrapper #header .header-content h1 {
    font-weight: 400;
  }
  .landing-wrapper #features,
  .landing-wrapper #promotion,
  .landing-wrapper #pricing,
  .landing-wrapper #video,
  .landing-wrapper #footer .footer-content {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    box-sizing: border-box;
  }
  .landing-wrapper #promotion .promotion-content {
    width: 100%;
  }
  .landing-wrapper #pricing .pricing-content {
    width: 100%;
  }
  .landing-wrapper #pricing .pricing-content button {
    width: 90%;
  }
  .landing-wrapper #video .video-content {
    width: 100%;
  }
  .landing-wrapper #video .video-content .video-container iframe {
    width: 350px;
    height: 220px;
  }
  .landing-wrapper #footer .footer-content .footer-right {
    text-align: left;
  }
}
.splash-screen {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
}
.splash-screen .splash-container {
  padding-top: 200px;
  width: 250px;
  margin: 0 auto;
  text-align: center;
}
.splash-screen .box {
  width: 300px;
  height: 300px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.splash-screen .wave {
  opacity: 0.4;
  position: absolute;
  top: 3%;
  left: 50%;
  background: #293978;
  width: 500px;
  height: 500px;
  margin-left: -250px;
  margin-top: -250px;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 3000ms infinite linear;
}
.splash-screen .wave.-three {
  animation: drift 2500ms infinite linear;
}
.splash-screen .wave.-two {
  animation: drift 5000ms infinite linear;
  opacity: 0.1;
  background: #293978;
}
.splash-screen .box:after {
  content: '';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #00acac, rgba(41, 57, 120, 0) 80%, rgba(255, 255, 255, 0.5));
  z-index: 11;
  transform: translate3d(0, 0, 0);
}
.splash-screen .title {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  line-height: 300px;
  text-align: center;
  transform: translate3d(0, 0, 0);
  color: white;
  text-transform: uppercase;
  font-family: 'Playfair Display', serif;
  letter-spacing: 0.4em;
  font-size: 24px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  text-indent: 0.3em;
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
html {
  height: 100%;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  color: #5f666c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #d9e0e7;
}
body a {
  color: #2f8ee5;
  text-decoration: none;
}
body a:hover {
  color: #469ae8;
}

.layout-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #424242;
  opacity: 0.7;
  filter: alpha(opacity=70);
  top: 0;
  left: 0;
  z-index: 999999997;
}

.layout-wrapper .topbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 75px;
  background-color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}
.layout-wrapper .topbar .logo img {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.layout-wrapper .topbar .topbar-left {
  box-sizing: border-box;
  padding: 25px;
  height: 75px;
  width: 250px;
  background-color: #f0f3f5;
  float: left;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-wrapper .topbar .topbar-right {
  padding: 15px 20px 15px 270px;
}
.layout-wrapper .topbar .topbar-right #menu-button {
  display: inline-block;
  vertical-align: middle;
  height: 36px;
  margin-right: 10px;
  position: relative;
  top: 3px;
  color: #5f666c;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.layout-wrapper .topbar .topbar-right #menu-button:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.layout-wrapper .topbar .topbar-right #menu-button i {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 36px;
}
.layout-wrapper .topbar .topbar-right #topbar-menu-button {
  display: none;
  float: right;
  color: #5f666c;
  vertical-align: middle;
  height: 36px;
  margin: 5px 5px 0 0;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.layout-wrapper .topbar .topbar-right #topbar-menu-button i {
  font-size: 36px;
}
.layout-wrapper .topbar .topbar-right #topbar-menu-button:hover {
  color: #2f8ee5;
}
.layout-wrapper .layout-menu-container {
  overflow: auto;
  position: fixed;
  width: 250px;
  z-index: 99;
  top: 75px;
  height: 100%;
  background-color: #2d353c;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.layout-wrapper .layout-menu-container .ui-scrollpanel {
  background: transparent;
  border-radius: 0;
  border: none;
}
.layout-wrapper .layout-menu-container .ui-scrollpanel .ui-scrollpanel-bar {
  background-color: #aaaaaa;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.layout-wrapper .layout-menu-container .ui-scrollpanel .ui-scrollpanel-hidden {
  display: block;
  visibility: hidden;
}
.layout-wrapper .layout-menu-container .ui-scrollpanel .menu-scroll-content {
  width: 268px;
  padding-right: 18px;
  padding-bottom: 120px;
  overflow: auto;
}
.layout-wrapper .layout-menu-container .ui-scrollpanel > ul:last-child {
  margin-top: 16px;
  padding-bottom: 120px;
}
.layout-wrapper .layout-menu-container .search-input {
  padding: 24px 16px 16px 16px;
  text-align: center;
  position: relative;
}
.layout-wrapper .layout-menu-container .search-input input {
  padding: 10px 30px 10px 15px;
  background-color: #42494f;
  border: 1px solid #75787b;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-size: 14px;
  color: #f0f3f5;
  box-sizing: border-box;
  width: 100%;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.layout-wrapper .layout-menu-container .search-input input::-webkit-input-placeholder {
  color: #a8acb1;
}
.layout-wrapper .layout-menu-container .search-input input:-moz-placeholder {
  color: #a8acb1;
}
.layout-wrapper .layout-menu-container .search-input input::-moz-placeholder {
  color: #a8acb1;
}
.layout-wrapper .layout-menu-container .search-input input:-ms-input-placeholder {
  color: #a8acb1;
}
.layout-wrapper .layout-menu-container .search-input input:focus {
  outline: 0 none;
  border: 1px solid #2f8ee5;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
.layout-wrapper .layout-menu-container .search-input .fa-search {
  position: absolute;
  right: 24px;
  top: 32px;
  font-size: 22px;
  color: #a8acb1;
}
.layout-wrapper .layout-menu-container .layout-menu-footer {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 14px 120px;
  color: #a8acb1;
  margin-top: 20px;
  font-size: 12px;
}
.layout-wrapper .layout-menu-container .layout-menu-footer .layout-menu-footer-title {
  width: 100%;
  font-size: 14px;
  display: block;
  border-bottom: 1px solid #86898d;
}
.layout-wrapper .layout-menu-container .layout-menu-footer .ui-progressbar {
  height: 5px;
  border: 0 none;
  margin: 16px 0 4px 0;
}
.layout-wrapper.menu-layout-overlay .layout-menu-container {
  margin-left: -250px;
}
.layout-wrapper.menu-layout-overlay .layout-main {
  margin-left: 0px;
}
.layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .layout-menu-container {
  z-index: 999999999;
  margin-left: 0px;
}
.layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .layout-mask {
  display: block;
}
.layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .topbar .topbar-right #menu-button i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.layout-wrapper.menu-layout-overlay .topbar {
  z-index: 999999998;
}
.layout-wrapper.menu-layout-overlay .topbar .topbar-left {
  background-color: #f0f3f5;
}
.layout-wrapper.menu-layout-overlay .topbar .topbar-right #menu-button i {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.layout-wrapper .layout-main {
  padding: 75px 0 15px 0;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.layout-wrapper .layout-main .route-bar {
  padding: 5px 20px 5px 25px;
  background-color: #f0f3f5;
}
.layout-wrapper .layout-main .route-bar .fa {
  margin-right: 2px;
}
.layout-wrapper .layout-main .layout-main-content {
  padding: 15px;
}
.layout-wrapper .layout-mask {
  display: none;
}

/* --- */

.layout-wrapper .layout-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.layout-wrapper .layout-menu li a {
  display: block;
  padding: 8px 16px;
  color: #a8acb1;
  width: 100%;
  cursor: pointer;
  user-select: none;
  outline: none;
  line-height: 14px;
  box-sizing: border-box;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  overflow: hidden;
}
.layout-wrapper .layout-menu li a i {
  color: #a8acb1;
}
.layout-wrapper .layout-menu li a i:first-child {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-size: 18px;
}
.layout-wrapper .layout-menu li a .menuitem-toggler {
  float: right;
  font-size: 20px;
  margin-right: -3px;
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.layout-wrapper .layout-menu li a span {
  display: inline-block;
  vertical-align: middle;
}
.layout-wrapper .layout-menu li a:hover {
  background-color: #444d56;
}
.layout-wrapper .layout-menu li a.active-menuitem-routerlink {
  color: #ffffff;
}
.layout-wrapper .layout-menu li a.active-menuitem-routerlink i {
  color: #ffffff;
}
.layout-wrapper .layout-menu li.active-menuitem > a {
  color: #2f8ee5;
}
.layout-wrapper .layout-menu li.active-menuitem > a i {
  color: #2f8ee5;
}
.layout-wrapper .layout-menu li.active-menuitem > a i.menuitem-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.layout-wrapper .layout-menu li ul {
  background-color: #1a2229;
  padding: 0 0 0 20px;
  margin: 0;
  list-style: none;
  overflow: hidden;
}
.layout-wrapper .layout-menu li.orange-badge > a .menuitem-badge {
  background-color: #efa64c;
  color: #ffffff;
}
.layout-wrapper .layout-menu li.blue-badge > a .menuitem-badge {
  background-color: #2f8ee5;
  color: #ffffff;
}
.layout-wrapper .layout-menu li.deeppurple-badge > a .menuitem-badge {
  background-color: #7e57c2;
  color: #ffffff;
}
.layout-wrapper .layout-menu > li.active-menuitem > a {
  background-color: #2f8ee5;
  color: #ffffff;
}
.layout-wrapper .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}
.layout-wrapper .layout-menu > li.active-menuitem > a i.menuitem-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.layout-wrapper .layout-menu .menuitem-badge {
  position: relative;
  float: right;
  margin-top: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  text-align: center;
  background-color: #2f8ee5;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.layout-wrapper .footer {
  /* padding: 0.5em; */
  margin-top: 30px;
}
.layout-wrapper .footer .footer-text-left {
  float: left;
}
.layout-wrapper .footer .footer-text-right {
  color: #778087;
  float: right;
}
.layout-wrapper .footer .footer-text-right span {
  vertical-align: middle;
  display: inline-block;
}

.ajax-loader {
  font-size: 32px;
  color: #2f8ee5;
}

@media (min-width: 1025px) {
  .layout-wrapper .topbar-items {
    float: right;
    margin: 0;
    padding: 5px 0 0 0;
    list-style-type: none;
  }
  .layout-wrapper .topbar-items > li {
    float: right;
    position: relative;
    margin-left: 25px;
  }
  .layout-wrapper .topbar-items > li > a {
    position: relative;
    display: flex;
    color: #5f666c;
  }
  .layout-wrapper .topbar-items > li > a .topbar-item-name {
    /* display: none; */
    display: block;
    font-size: 0.8em;
    text-transform: uppercase;
    margin-left: 10px;
    min-width: 100px;
  }
  .layout-wrapper .topbar-items > li > a .topbar-item-name small {
    font-weight: bolder;
    display: block;
    margin-top: -15px;
  }
  .layout-wrapper .topbar-items > li > a .topbar-badge {
    position: absolute;
    right: -1px;
    top: -5px;
    background-color: #2f8ee5;
    color: #ffffff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 2px 4px;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  .layout-wrapper .topbar-items > li .topbar-icon {
    font-size: 36px;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .layout-wrapper .topbar-items > li .topbar-icon:hover {
    color: #2f8ee5;
  }
  .layout-wrapper .topbar-items > li.profile-item .profile-image {
    width: 36px;
    height: 36px;
  }
  .layout-wrapper .topbar-items > li > ul {
    position: absolute;
    top: 57px;
    right: 5px;
    /* display: none; */
    width: 250px;
    background-color: #ffffff;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
    list-style-type: none;
    margin: 0;
    padding: 8px 0;
    -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .layout-wrapper .topbar-items > li > ul a {
    padding: 8px 16px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    color: #5f666c;
  }
  .layout-wrapper .topbar-items > li > ul a .fa {
    margin-right: 8px;
  }
  .layout-wrapper .topbar-items > li > ul a:hover {
    background-color: #f0f3f5;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .layout-wrapper .topbar-items > li.active-top-menu > ul {
    display: block;
  }
  .layout-wrapper .topbar-items > li .topbar-message img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  .layout-wrapper.menu-layout-static .layout-menu-container {
    margin-left: 0;
  }
  .layout-wrapper.menu-layout-static .layout-main {
    margin-left: 250px;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .topbar .topbar-right #menu-button i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .layout-menu-container {
    margin-left: -250px;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .layout-main {
    margin-left: 0px;
  }
  .layout-wrapper.menu-layout-static .layout-mask {
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .topbar {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-wrapper.menu-layout-horizontal .topbar .topbar-left {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    background-color: #ffffff;
  }
  .layout-wrapper.menu-layout-horizontal .topbar .topbar-right #menu-button {
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container {
    overflow: visible;
    position: fixed;
    width: 100%;
    top: 75px;
    height: auto;
    padding-left: 18px;
    background-color: #f0f3f5;
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .ui-scrollpanel {
    overflow: visible;
    border: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .ui-scrollpanel .ui-scrollpanel-wrapper {
    overflow: visible;
  }
  .layout-wrapper.menu-layout-horizontal
    .layout-menu-container
    .ui-scrollpanel
    .ui-scrollpanel-wrapper
    .ui-scrollpanel-content {
    overflow: visible;
    padding: 0;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .ui-scrollpanel .menu-scroll-content {
    width: 100%;
    overflow: visible;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .ui-scrollpanel > ul:last-child {
    margin-top: 0;
    padding-bottom: 0;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .search-input {
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li {
    float: left;
    position: relative;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > a {
    text-align: center;
    color: #5f666c;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > a i:first-child {
    color: #5f666c;
    width: 100%;
    margin: 4px 0;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > a .menuitem-toggler {
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > a:hover {
    background-color: #c9cdd2;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li a.active-menuitem-routerlink {
    color: #1974c8;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li a.active-menuitem-routerlink i {
    color: #1974c8;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > ul {
    z-index: 100;
    position: absolute;
    top: 56px;
    left: 0px;
    width: 250px;
    padding: 0;
    background-color: #f0f3f5;
    -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > ul li a {
    color: #5f666c;
    padding: 10px 16px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > ul li a i {
    color: #5f666c;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: #c9cdd2;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > ul li ul {
    padding: 0 0 0 20px;
    position: static;
    background-color: #f0f3f5;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #2f8ee5;
    background-color: #e8e8e8;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: #2f8ee5;
    color: #ffffff;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu .menuitem-badge {
    position: absolute;
    right: calc(50% - 30px);
    top: 5px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-menu-container .layout-menu-footer {
    display: none;
  }
  .layout-wrapper.menu-layout-horizontal .layout-main {
    padding-top: 130px;
    margin-left: 0px;
  }
  .layout-wrapper.menu-layout-horizontal .layout-main .route-bar {
    background-color: #f0f3f5;
  }
  .layout-wrapper.menu-layout-horizontal .layout-mask {
    display: none;
  }
}
@media (max-width: 1024px) {
  .layout-wrapper.menu-layout-static .topbar .topbar-left {
    background-color: #f0f3f5;
  }
  .layout-wrapper.menu-layout-static .topbar .topbar-right #menu-button i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .layout-wrapper.menu-layout-static .layout-menu-container {
    margin-left: -265px;
  }
  .layout-wrapper.menu-layout-static .layout-main {
    margin-left: 0px;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .layout-menu-container {
    margin-left: 0;
    z-index: 999999999;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .topbar {
    z-index: 999999998;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .topbar .topbar-right #menu-button i {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .layout-main {
    margin-left: 0;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-active .layout-mask {
    display: block;
  }
  .layout-wrapper .topbar .topbar-right #topbar-menu-button {
    display: block;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items {
    position: absolute;
    top: 77px;
    right: 15px;
    width: 275px;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
    display: none;
    background-color: #ffffff;
    list-style-type: none;
    margin: 0;
    padding: 8px 0;
    -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 8px 16px;
    color: #5f666c;
    position: relative;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    font-size: 24px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a:hover {
    background-color: #f0f3f5;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a .topbar-item-name {
    display: inline-block;
    vertical-align: middle;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > a .topbar-badge {
    position: absolute;
    left: 30px;
    top: 10px;
    background-color: orange;
    color: yellow;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 2px 4px;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul {
    display: none;
    list-style-type: none;
    padding: 0 0 0 20px;
    margin: 0;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a {
    padding: 8px 16px 8px 24px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    color: #5f666c;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a span,
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a img,
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a .fa {
    display: inline-block;
    vertical-align: middle;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a img {
    width: 1.28571429em;
    margin-right: 8px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a .fa {
    margin-right: 8px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li > ul li a:hover {
    background-color: #f0f3f5;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.active-top-menu > a {
    background-color: #2f8ee5;
    color: #ffffff;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.active-top-menu > ul {
    display: block;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.profile-item .profile-image {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 4px;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.profile-item span {
    vertical-align: middle;
    display: inline-block;
  }
  .layout-wrapper .topbar .topbar-right .topbar-items.topbar-items-visible {
    display: block;
  }
}
@media (max-width: 640px) {
  .layout-wrapper.menu-layout-static .topbar .topbar-left,
  .layout-wrapper.menu-layout-overlay .topbar .topbar-left,
  .layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .topbar .topbar-left {
    float: none;
    margin: 0 auto;
    background-color: #ffffff;
  }
  .layout-wrapper.menu-layout-static .topbar .topbar-right,
  .layout-wrapper.menu-layout-overlay .topbar .topbar-right,
  .layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .topbar .topbar-right {
    position: relative;
    top: -75px;
    padding: 15px 20px 15px 25px;
  }
  .layout-wrapper.menu-layout-static .topbar .topbar-right #menu-button,
  .layout-wrapper.menu-layout-overlay .topbar .topbar-right #menu-button,
  .layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .topbar .topbar-right #menu-button {
    font-size: 44px;
  }
  .layout-wrapper.menu-layout-static .topbar .topbar-right #menu-button i,
  .layout-wrapper.menu-layout-overlay .topbar .topbar-right #menu-button i,
  .layout-wrapper.menu-layout-overlay.layout-menu-overlay-active .topbar .topbar-right #menu-button i {
    vertical-align: top;
  }
}
/* Add your customizations of layout here */

body .ui-panelmenu {
  margin: 30px 0 !important;
}
body .ui-panelmenu .ui-menuitem {
  margin: 0 !important;
}
body .ui-panelmenu .ui-corner-all {
  border: none !important;
  border-radius: 0 !important;
}
body .ui-panelmenu .ui-panelmenu-panel {
  margin: 0 !important;
}
body .ui-panelmenu .ui-panelmenu-header {
  background-color: transparent !important;
  padding: 0 !important;
  border: none !important;
  margin: 0;
}
body .ui-panelmenu .ui-panelmenu-content {
  background-color: #1a2229 !important;
  border: none !important;
  padding: 0 0 0 20px !important;
  margin: 0 !important;
  overflow: hidden !important;
}
body .ui-panelmenu a {
  display: block !important;
  padding: 8px 16px !important;
  color: #a8acb1 !important;
  width: 100% !important;
  cursor: pointer !important;
  user-select: none !important;
  outline: none !important;
  line-height: 14px !important;
  box-sizing: border-box !important;
  -moz-transition: background-color 0.3s !important;
  -o-transition: background-color 0.3s !important;
  -webkit-transition: background-color 0.3s !important;
  transition: background-color 0.3s !important;
  overflow: hidden !important;
}
body .ui-panelmenu a:hover {
  background-color: #444d56 !important;
}
body .ui-panelmenu a.ui-state-active,
body .ui-panelmenu a.ui-state-highlight,
body .ui-panelmenu .ui-panelmenu-header.ui-state-active a,
body .ui-panelmenu .ui-panelmenu-header.ui-state-highlight a {
  background-color: #2f8ee5 !important;
  color: #ffffff !important;
}
body .ui-panelmenu .ui-panelmenu-content a.ui-state-active,
body .ui-panelmenu .ui-panelmenu-content a.ui-state-highlight {
  background-color: transparent !important;
  color: #2f8ee5 !important;
}
body .ui-panelmenu a .ui-menuitem-text {
  display: inline-block !important;
  vertical-align: middle !important;
}
body .ui-panelmenu a.ui-state-active .ui-menuitem-text,
body .ui-panelmenu a.ui-state-highlight .ui-menuitem-text {
  color: #ffffff !important;
}
body .ui-panelmenu .ui-panelmenu-content a.ui-state-active .ui-menuitem-text,
body .ui-panelmenu .ui-panelmenu-content a.ui-state-highlight .ui-menuitem-text {
  color: #2f8ee5 !important;
}
body .ui-panelmenu a .ui-menuitem-icon {
  color: #a8acb1;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-size: 18px;
  width: 20px;
  text-align: center;
}
body .ui-panelmenu a.ui-state-active .ui-menuitem-icon,
body .ui-panelmenu a.ui-state-highlight .ui-menuitem-icon {
  color: #ffffff;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active a .ui-menuitem-icon,
body .ui-panelmenu .ui-panelmenu-header.ui-state-highlight a .ui-menuitem-icon {
  color: #ffffff;
}
body .ui-panelmenu .ui-panelmenu-content a.ui-state-active .ui-menuitem-icon,
body .ui-panelmenu .ui-panelmenu-content a.ui-state-highlight .ui-menuitem-icon {
  color: #2f8ee5;
}
body .ui-panelmenu a .ui-panelmenu-icon {
  color: #a8acb1;
  float: right;
  font-size: 20px;
  margin-right: -3px;
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active a .ui-panelmenu-icon,
body .ui-panelmenu .ui-panelmenu-header.ui-state-highlight a .ui-panelmenu-icon {
  color: #ffffff;
}

body .ui-panelmenu .titleItem {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 0 !important;
}
body .ui-panelmenu .titleItem a {
  padding-bottom: 5px !important;
}
body .ui-panelmenu .titleItem a:hover {
  background-color: transparent !important;
}
body .ui-panelmenu .titleItem .ui-menuitem-icon {
  display: none !important;
}
body .ui-panelmenu .titleItem .ui-menuitem-text {
  text-transform: uppercase !important;
  font-size: 10px !important;
}

/* --- */

.note-editor.note-frame.fullscreen,
.note-editor.note-airframe.fullscreen {
  margin-left: 0 !important;
  top: 0 !important;
}
