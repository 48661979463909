/* You can add global styles to this file, and also import other style files */
@import './assets/sass/custom/general.scss';
@import './assets/sass/custom/primeng.scss';
@import './assets/sass/custom/jsoneditor.scss';
@import './assets/layout/css/layout-fincantieri.css';
@import './assets/sass/corporate';
@import './assets/sass/corporate_custom';
@import 'primeflex/primeflex.scss';
@import './assets/theme/theme-saga-blue.css';
@import '../node_modules/ckeditor5/dist/ckeditor5.css';
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');



@font-face {
  font-family: 'Druk Wide';
  src: url('./assets/font/druk/druk-wide-medium.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT std';
  src: url('./assets/font/tradegothicltstd/tradegothicltstd.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fraktion Mono';
  src: url('./assets/font/fraktionmono/fraktionmono-medium.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}
