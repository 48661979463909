/* From Corporate style */
$grey-1: #202020;
$grey-2: #323232;
$bold: 700;

/* Add Dark Gray Button in PrimeNG */
.ui-button-dark {
  background-color: #808080 !important;

  &:hover {
    background-color: #606060 !important;
  }
}

.ui-inputtext.ng-dirty {
  border: 1px solid #cccccc !important;
}

/* Add Badges in PrimeNG */
.ui-badge {
  display: inline-block;
  margin-bottom: 4px;
  border-radius: 3px;
  padding: 4px 8px;
  color: white;
  font-size: 0.9em;

  &.ui-badge-warning {
    color: black;
    background-color: #ffc107;
  }

  &.ui-badge-danger {
    background-color: #dc3545;
  }

  &.ui-badge-success {
    background-color: #28a745;
  }

  &.ui-badge-primary {
    background-color: #007bff;
  }

  &.ui-badge-secondary {
    background-color: #6c757d;
  }

  &.ui-badge-info {
    background-color: #17a2b8;
  }

  &.ui-badge-dark {
    background-color: #323232;
  }
}

/* Remove Red Color in Menu Text */
.layout-menu li.active-menuitem > a,
.layout-menu li a.active-menuitem-routerlink {
  color: black !important;
}

.layout-menu li.active-menuitem > a i:first-child,
.layout-menu li a.active-menuitem-routerlink i:first-child {
  color: gray !important;
}

/* Responsive DropDown */
.ui-dropdown {
  min-width: 60px !important;
}

/* File Upload Button */
.ui-fileupload-choose {
  border-radius: 0 !important;
  color: white !important;
  border: 1px solid $grey-2 !important;
  background-color: $grey-2 !important;
  font-weight: $bold !important;
  font-style: normal !important;

  &:hover,
  &:focus,
  &:active {
    border-radius: 0 !important;
    border: 1px solid $grey-2 !important;
    color: $grey-1 !important;
    background-color: white !important;
  }
}

/* Table Sort Header */
.ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #323232 !important;
  border-top-color: #323232 !important;
}

/* checkbox List */
.ui-listbox-item {
  &:hover {
    background-color: white !important;
  }
}

.ui-listbox-item.ui-state-highlight {
  color: black !important;
  background-color: white !important;
}

/* Slider */
.ui-slider .ui-slider-range {
  background-color: #323232 !important;
}

.ui-slider .ui-slider-handle {
  background-color: #323232 !important;
}
